const pysIcons = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M1008.845 885.76c-7.753-6.889-18.022-11.098-29.275-11.098-1.944 0-3.86 0.126-5.738 0.369l0.222-0.024c-46.514 6.446-100.258 10.126-154.861 10.126-14.14 0-28.223-0.247-42.244-0.736l2.034 0.056c-51.024-2.744-98.897-12.139-144.095-27.352l3.807 1.112-155.674-447.59c148.352-48 369.51-85.171 376.32-98.074 3.285-6.053 5.216-13.252 5.216-20.903 0-7.457-1.835-14.486-5.077-20.659l0.117 0.244c-76.54-145.7-226.819-243.371-399.91-243.371-37.482 0-73.895 4.58-108.708 13.211l3.095-0.649-3.738-10.726c-6.11-17.448-22.434-29.737-41.629-29.737-5.266 0-10.317 0.925-14.997 2.622l0.306-0.097-0.717 0.256c-17.354 6.279-29.532 22.614-29.532 41.794 0 5.203 0.896 10.198 2.543 14.836l-0.096-0.31 3.712 10.701c-155.407 74.055-260.892 229.871-260.892 410.325 0 29.866 2.889 59.056 8.404 87.308l-0.465-2.861c2.849 14.683 12.642 26.589 25.751 32.324l0.285 0.111c13.517 5.811 199.91-98.867 355.84-157.030l131.072 376.448c-105.754-45.030-206.925-92.16-340.48-71.68-85.402 13.082-152.755 80.589-178.202 109.619-6.89 7.76-11.133 18.005-11.238 29.239l-0 0.022v96.128c0.044 24.442 19.846 44.244 44.284 44.288l0.004 0h935.398c24.453-0.029 44.27-19.837 44.314-44.284l0-0.004v-60.826c-0.098-13.238-5.919-25.097-15.108-33.239l-0.047-0.041z',
        ],
        attrs: [
          {
            fill: 'rgb(95, 95, 94)',
          },
        ],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: [
          'vacaciones',
        ],
        colorPermutations: {
          '25525525519595941': [
            {
              f: 0,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(95, 95, 94)',
        },
      ],
      properties: {
        order: 7,
        id: 3,
        name: 'vacaciones',
        prevSize: 32,
        code: 59648,
      },
      setIdx: 0,
      setId: 7,
      iconIdx: 0,
    },
    {
      icon: {
        paths: [
          'M1163.081 939.122h-777.984v-289.358c0.008-0.309 0.013-0.673 0.013-1.038 0-23.068-18.7-41.768-41.768-41.768s-41.768 18.7-41.768 41.768c0 0.365 0.005 0.729 0.014 1.092l-0.001-0.054v289.358h-113.669v-320.884c0.008-0.309 0.013-0.673 0.013-1.038 0-23.068-18.7-41.768-41.768-41.768s-41.768 18.7-41.768 41.768c0 0.365 0.005 0.729 0.014 1.092l-0.001-0.054v320.884h-62.639c-23.438-0-42.439 19-42.439 42.439s19 42.439 42.439 42.439h1119.016c23.055-0.13 41.694-18.849 41.694-41.922 0-0.191-0.001-0.381-0.004-0.571l0 0.029c0.116-1.125 0.182-2.431 0.182-3.752 0-21.372-17.326-38.698-38.698-38.698-0.327 0-0.653 0.004-0.979 0.012l0.048-0.001z',
          'M646.824 149.864c0.507 0.026 1.102 0.041 1.7 0.041 9.309 0 17.783-3.575 24.123-9.428l-0.024 0.022c40.641-30.882 90.422-51.821 144.642-58.32l1.43-0.14c21.102-1.878 37.522-19.473 37.522-40.904 0-22.675-18.381-41.056-41.056-41.056-1.244 0-2.476 0.055-3.692 0.164l0.157-0.011c-73.107 6.809-138.62 34.787-191.578 77.662l0.668-0.524c-9.905 7.553-16.233 19.36-16.233 32.645 0 9.821 3.458 18.833 9.222 25.888l-0.058-0.073c8.919 7.687 20.267 12.786 32.751 14.015l0.245 0.020z',
          'M1072.269 314.537v661.839c1.42 23.242 20.616 41.554 44.090 41.554s42.67-18.312 44.084-41.429l0.006-0.124v-661.839c-0.662-23.050-19.506-41.483-42.654-41.483-0.505 0-1.008 0.009-1.508 0.026l0.073-0.002c-24.509 2.296-44.090-77.396-44.090-56.757z',
          'M104.407 554.955s12.693-25.515 38.982 0 8.643 43.11 8.643 43.11l-28.379 23.219z',
          'M1116.359 227.002s44.090 38.982 44.090 77.602v9.932l-61.762 40.091s-13.441-108.019-13.441-109.412 31.113-18.214 31.113-18.214z',
          'M157.81 628.197v353.493h958.523v-708.612l-96.848-71.875-141.686-35.551-188.975 37.872-56.422 108.303-163.873 214.051-94.371 73.965-216.347 28.353z',
          'M983.806 198.288h51.056c13.878 0 25.128 11.25 25.128 25.128v673.758c0 13.878-11.25 25.128-25.128 25.128h-51.056c-13.878 0-25.128-11.25-25.128-25.128v-673.758c0-13.878 11.25-25.128 25.128-25.128z',
          'M797.436 163.486h51.056c13.878 0 25.128 11.25 25.128 25.128v710.005c0 13.878-11.25 25.128-25.128 25.128h-51.056c-13.878 0-25.128-11.25-25.128-25.128v-710.005c0-13.878 11.25-25.128 25.128-25.128z',
          'M606.887 258.709h51.056c13.878 0 25.128 11.25 25.128 25.128v615.298c0 13.878-11.25 25.128-25.128 25.128h-51.056c-13.878 0-25.128-11.25-25.128-25.128v-615.298c0-13.878 11.25-25.128 25.128-25.128z',
          'M415.178 560.295h51.056c13.878 0 25.128 11.25 25.128 25.128v313.712c0 13.878-11.25 25.128-25.128 25.128h-51.056c-13.878 0-25.128-11.25-25.128-25.128v-313.712c0-13.878 11.25-25.128 25.128-25.128z',
          'M228.808 615.349h51.056c13.878 0 25.128 11.25 25.128 25.128v258.657c0 13.878-11.25 25.128-25.128 25.128h-51.056c-13.878 0-25.128-11.25-25.128-25.128v-258.657c0-13.878 11.25-25.128 25.128-25.128z',
          'M980.607 144.963c-33.393-12.869-72.029-20.323-112.406-20.323-57.828 0-112.084 15.291-158.946 42.049l1.576-0.829c-105.568 58.047-152.496 153.244-194.702 234.51-53.971 109.128-98.551 197.36-262.759 197.36-3.796 0.389-8.202 0.611-12.66 0.611-39.267 0-74.512-17.225-98.588-44.532l-0.124-0.143c-10.139-11.351-5.882 37.614-12.667 23.993l-24.921-22.729v74.352c41.501 28.23 92.724 45.072 147.881 45.072 0.38 0 0.76-0.001 1.139-0.002l-0.059 0c218.050 0 281.515-127.703 337.808-241.475 39.885-78.944 77.396-153.244 159.539-199.682 138.41-74.3 288.558 6.966 344.851 95.197 7.566 11.783 20.603 19.479 35.437 19.479 7.674 0 14.867-2.059 21.056-5.655l-0.201 0.108c3.291-2.294 6.12-4.944 8.53-7.944l0.061-0.079-44.090-107.297c-38.062-34.87-83.207-62.734-132.943-81.13l-2.81-0.91z',
        ],
        attrs: [
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(255, 255, 255)',
          },
          {
            fill: 'rgb(255, 255, 255)',
          },
          {
            fill: 'rgb(255, 255, 255)',
          },
          {
            fill: 'rgb(255, 255, 255)',
          },
          {
            fill: 'rgb(255, 255, 255)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
        ],
        width: 1203,
        isMulticolor: true,
        isMulticolor2: false,
        grid: 0,
        tags: [
          'escapada-visita-1-dia',
        ],
        colorPermutations: {
          '25525525519595941': [
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 1,
            },
            {
              f: 1,
            },
            {
              f: 1,
            },
            {
              f: 1,
            },
            {
              f: 1,
            },
            {
              f: 0,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(255, 255, 255)',
        },
        {
          fill: 'rgb(255, 255, 255)',
        },
        {
          fill: 'rgb(255, 255, 255)',
        },
        {
          fill: 'rgb(255, 255, 255)',
        },
        {
          fill: 'rgb(255, 255, 255)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
      ],
      properties: {
        order: 8,
        id: 2,
        name: 'escapada-visita-1-dia',
        prevSize: 32,
        code: 59649,
        codes: [
          59649,
          59650,
          59651,
          59652,
          59653,
          59654,
          59655,
          59656,
          59657,
          59658,
          59659,
          59660,
        ],
      },
      setIdx: 0,
      setId: 7,
      iconIdx: 1,
    },
    {
      icon: {
        paths: [
          'M1262.324 752.176c-110.117-76.577-297.56-115.28-407.677-115.28-147.54 1.874-285.228 42.39-403.908 111.859l3.877-2.097c123.005 32.042 223.505 103.338 223.505 197.060v75.237h379.891v-75.001c-0.039-89.11 90.253-157.569 204.311-191.778z',
          'M1103.297 254.798c-3.414 134.694-113.415 242.58-248.614 242.58-137.347 0-248.689-111.342-248.689-248.689 0-137.335 111.322-248.669 248.652-248.689l0.002-0c137.53 0.848 248.692 112.533 248.692 250.182 0 1.623-0.015 3.242-0.046 4.858l0.004-0.242z',
          'M2215.501 254.798c-3.414 134.694-113.415 242.58-248.614 242.58-137.347 0-248.689-111.342-248.689-248.689s111.342-248.689 248.689-248.689c0.001 0 0.003 0 0.004 0l-0-0c137.509 0.847 248.654 112.515 248.654 250.143 0 1.637-0.016 3.27-0.047 4.899l0.004-0.244z',
          'M1966.89 636.975c-147.539 2.384-285.138 42.825-404.048 111.895l3.977-2.133c122.965 32.042 223.505 103.338 223.505 197.060v75.198h379.891v-75.040c0-89.11 90.253-157.648 204.351-191.778-110.117-76.577-296.18-115.201-407.677-115.201z',
          'M468.174 544.475c-2.094 84.57-71.147 152.327-156.026 152.327-86.196 0-156.071-69.875-156.071-156.071 0-86.152 69.805-156.001 155.941-156.071l0.007-0c86.36 0.501 156.174 70.624 156.174 157.054 0 0.971-0.009 1.94-0.026 2.906l0.002-0.145z',
          'M400.070 792.179c-26.312-4.947-56.706-7.904-87.746-8.157l-0.222-0.001c-144.484 0-312.103 69.838-312.103 159.933v80.046h623.851v-80.046c0-71.848-107.082-130.769-223.82-151.775z',
          'M2692.739 544.475c-1.031 86.473-71.371 156.177-157.992 156.177-87.262 0-158.002-70.74-158.002-158.002s70.74-158.002 158.002-158.002c44.063 0 83.913 18.037 112.57 47.13l0.019 0.020c28.084 28.819 45.405 68.244 45.405 111.714 0 0.339-0.001 0.678-0.003 1.017l0-0.052z',
          'M2536.55 784.021c-39.626 0.26-78.031 4.913-114.927 13.497l3.51-0.688c-107.122 24.041-200.685 80.085-200.685 147.046v80.046h623.851v-80.046c0-90.017-167.619-159.855-311.748-159.855z',
          'M1580.378 544.475c0.003-0.351 0.004-0.766 0.004-1.181 0-65.296-39.072-121.465-95.111-146.405l-1.019-0.405c-17.697-7.724-38.316-12.219-59.985-12.219-43.229 0-82.28 17.887-110.156 46.664l-0.039 0.040c-28.368 29.266-45.855 69.223-45.855 113.263 0 22.005 4.365 42.99 12.277 62.136l-0.396-1.081c23.202 58.438 79.253 99.004 144.779 99.004 10.746 0 21.238-1.091 31.369-3.168l-1.002 0.172c72.015-15.382 125.238-78.471 125.238-153.992 0-1.008-0.009-2.014-0.028-3.018l0.002 0.15z',
          'M1512.274 792.179c-26.424-5.117-56.813-8.045-87.886-8.045-39.187 0-77.287 4.656-113.777 13.446l3.303-0.672c-107.122 24.041-200.685 80.046-200.685 147.046v80.046h622.708v-80.046c0.197-71.848-106.846-130.769-223.663-151.775z',
        ],
        attrs: [
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
        ],
        width: 2848,
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: [
          'en-familia',
        ],
        colorPermutations: {
          '25525525519595941': [
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
      ],
      properties: {
        order: 10,
        id: 1,
        name: 'en-familia',
        prevSize: 32,
        code: 59661,
      },
      setIdx: 0,
      setId: 7,
      iconIdx: 2,
    },
    {
      icon: {
        paths: [
          'M600.616 19.047c92.166 37.479 155.962 126.344 155.962 230.11s-63.796 192.631-154.306 229.513l-1.656 0.597c-28.511 12.006-61.653 18.983-96.425 18.983-69.473 0-132.442-27.848-178.352-72.991l0.035 0.035c-45.562-44.825-73.794-107.146-73.794-176.061 0-34.435 7.049-67.224 19.783-97.003l-0.612 1.611c39.621-91.239 128.943-153.892 232.897-153.892 34.798 0 67.955 7.020 98.135 19.722l-1.666-0.622z',
          'M840.27 699.751c-94.823-37.451-204.566-60.393-319.307-63.171l-1.165-0.022c-173.74 0-519.838 87.398-519.838 258.173v129.269h708.483v-129.269c0-76.189 51.821-142.996 131.828-194.939z',
          'M2268.809 249.116c0.011 0.893 0.018 1.949 0.018 3.005 0 139.243-112.879 252.122-252.122 252.122s-252.122-112.879-252.122-252.122c0-139.237 112.868-252.111 252.102-252.122l0.001-0c0.448-0.003 0.977-0.005 1.507-0.005 137.886 0 249.764 111.354 250.614 249.040l0 0.081z',
          'M2548.913 894.731c0-171.871-346.22-258.173-519.513-258.173-116.024 2.735-225.902 25.683-327.346 65.439l6.508-2.246c79.56 51.984 131.543 118.832 131.543 194.939v129.269h708.807v-129.229z',
          'M1260.080 498.192c0.333 0.002 0.727 0.002 1.122 0.002 103.654 0 192.699-62.552 231.445-151.967l0.63-1.632c12.141-28.186 19.202-60.998 19.202-95.459 0-68.905-28.228-131.217-73.751-176.002l-0.033-0.033c-45.877-45.119-108.853-72.975-178.336-72.975-34.827 0-68.019 6.998-98.247 19.665l1.677-0.623c-92.072 37.812-155.727 126.782-155.727 230.62 0 137.314 111.315 248.629 248.629 248.629 1.192 0 2.382-0.008 3.57-0.025l-0.18 0.002z',
          'M1656.456 727.895c-111.069-57.549-242.472-91.297-381.756-91.297s-270.686 33.747-386.475 93.511l4.719-2.214c-81.834 44.43-136.498 104.374-136.498 166.836v129.269h1036.184v-129.269c0-62.462-54.461-122.406-136.173-166.836z',
        ],
        attrs: [
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
          {
            fill: 'rgb(95, 95, 94)',
          },
        ],
        width: 2549,
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: [
          'con-amigos',
        ],
        colorPermutations: {
          '25525525519595941': [
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
            {
              f: 0,
            },
          ],
        },
      },
      attrs: [
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
        {
          fill: 'rgb(95, 95, 94)',
        },
      ],
      properties: {
        order: 9,
        id: 0,
        name: 'con-amigos',
        prevSize: 32,
        code: 59662,
      },
      setIdx: 0,
      setId: 7,
      iconIdx: 3,
    },
  ],
  height: 1024,
  metadata: {
    name: 'icomoon',
  },
  preferences: {
    showGlyphs: true,
    showQuickUse: true,
    showQuickUse2: true,
    showSVGs: true,
    fontPref: {
      prefix: 'icon-',
      metadata: {
        fontFamily: 'icomoon',
        majorVersion: 1,
        minorVersion: 0,
      },
      metrics: {
        emSize: 1024,
        baseline: 6.25,
        whitespace: 50,
      },
      embed: false,
    },
    imagePref: {
      prefix: 'icon-',
      png: true,
      useClassSelector: true,
      color: 0,
      bgColor: 16777215,
      classSelector: '.icon',
    },
    historySize: 50,
    showCodes: true,
    gridSize: 16,
  },
};

export default pysIcons;
